import styled from "styled-components";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useState } from "react";
import {
  Link,
  Button,
  TextInputLabel,
  InfoBox,
  ErrorBox,
  PasswordStrength,
} from "@sussex/react-kit/elements";
import { validateEmail } from "@sussex/react-kit/utils";
import PhoneNumberInput from "../../PhoneNumberInput";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Loading } from "@sussex/react-kit/assets";
import Confirm from "../../Confirm";
import SignInFlow from "../../SignIn/Flow";
import Footer from "../Footer";
import useCopy from "../../../hooks/useCopy";
import userState, { locale as localeState } from "../../../state/user";
import providerState from "../../../state/provider";
import userPool from "../../../cognito";

const SignInText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const AccountTextWrapper = styled.div`
  margin-top: 16px;
  line-height: 22px;
`;

const Submit = styled(Button)`
  width: 100%;
  height: 56px;
`;

const ErrorList = styled.ul`
  margin: 10px 0;

  li {
    margin: 5px 0;
  }
`;

const SignUpForm = ({ setUser, next, setAuthDetails, footerRef }) => {
  const [
    nameText,
    emailText,
    passwordText,
    nameErrorText,
    emailErrorText,
    emailInUseErrorText,
    phoneErrorText,
    passwordErrorText,
    haveAccountText,
    signInWithAccountText,
    signInText,
    nextText,
    lengthText,
    uppercaseText,
    numberText,
  ] = useCopy([
    "signUp.name",
    "signIn.email",
    "signIn.password",
    "signUp.nameError",
    "signUp.emailError",
    "signUp.emailInUse",
    "signUp.phoneError",
    "signUp.passwordError",
    "steps.time.haveAccount",
    "steps.time.signInWithAccount",
    "signIn.signInLink",
    "steps.next",
    "steps.password.length",
    "steps.password.uppercase",
    "steps.password.number",
  ]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [genericError, setGenericError] = useState(null);
  const [validPassword, setValidPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const setUserState = useSetRecoilState(userState);
  const provider = useRecoilValue(providerState);
  const locale = useRecoilValue(localeState);

  const validName = name.length > 2;
  const validEmail = validateEmail(email);
  const validPhone = isValidPhoneNumber(phone);
  const formValid = validName && validEmail && validPassword && validPhone;

  const verifyForm = () => {
    setGenericError(null);
    setNameError(validName ? null : nameErrorText);
    setEmailError(validEmail ? null : emailErrorText);
    setPhoneError(validPhone ? null : phoneErrorText);
    setPasswordError(validPassword ? null : passwordErrorText);

    const isError = !(validName || validEmail || validPhone || validPassword);
    return isError;
  };

  const signUp = () => {
    setLoading(true);
    const isError = verifyForm();

    if (isError) {
      setLoading(false);
      setError(true);
      return;
    }

    const attributeEmail = new CognitoUserAttribute({
      Name: "email",
      Value: email,
    });
    const attributeName = new CognitoUserAttribute({
      Name: "name",
      Value: name,
    });
    const attributePhone = new CognitoUserAttribute({
      Name: "phone_number",
      Value: phone,
    });
    const attributeLocale = new CognitoUserAttribute({
      Name: "locale",
      Value: locale,
    });
    const attributeList = [
      attributeEmail,
      attributeName,
      attributePhone,
      attributeLocale,
    ];
    const authDetails = { email, password };
    setAuthDetails(authDetails);
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      setLoading(false);
      if (!err) {
        setUserState({
          signedIn: false,
          verified: false,
          username: email,
          email: email,
          name: name,
          phone: phone,
          locale: locale,
        });
        setUser(result.user);
        return;
      }
      setError(true);
      const { code, message } = err;
      if (code === "UsernameExistsException") {
        setEmailError(emailInUseErrorText);
        return;
      }
      if (code === "InvalidParameterException") {
        if (message === "Invalid email address format.") {
          setEmailError(emailErrorText);
          return;
        }
        if (message === "Invalid phone number format.") {
          setPhoneError(phoneErrorText);
          return;
        }
      }

      setGenericError(err.message);
      return;
    });
  };

  const SignInLink = () => (
    <Link onClick={() => setShowSignIn(true)}>{signInText}</Link>
  );

  if (showSignIn) {
    return (
      <SignInFlow
        next={next}
        canSkip={false}
        back={() => setShowSignIn(false)}
      />
    );
  }

  return (
    <div>
      <AccountTextWrapper>
        <InfoBox withIcon={false} coloredText={false}>
          <SignInText>
            <span>{haveAccountText} </span>
            {swapCopyVariables(signInWithAccountText, {
              LINK: <SignInLink />,
            })}
          </SignInText>
        </InfoBox>
      </AccountTextWrapper>
      {error && (
        <ErrorBox>
          <ErrorList>
            {genericError && <li>{genericError}</li>}
            {nameError && <li>{nameError}</li>}
            {emailError && <li>{emailError}</li>}
            {phoneError && <li>{phoneError}</li>}
            {passwordError && <li>{passwordError}</li>}
          </ErrorList>
        </ErrorBox>
      )}
      <TextInputLabel
        label={nameText}
        value={name}
        type="text"
        error={nameError}
        onChange={({ target }) => setName(target.value)}
      />
      <TextInputLabel
        label={emailText}
        value={email}
        type="email"
        error={emailError}
        onChange={({ target }) => setEmail(target.value)}
      />
      <PhoneNumberInput
        value={phone}
        type="tel"
        error={phoneError}
        country={provider.countryCode}
        onChange={setPhone}
      />
      <TextInputLabel
        label={passwordText}
        value={password}
        type="password"
        error={passwordError}
        onChange={({ target }) => setPassword(target.value)}
      />
      <PasswordStrength
        password={password}
        setValid={setValidPassword}
        lengthText={lengthText}
        uppercaseText={uppercaseText}
        numberText={numberText}
      />

      <Footer footerRef={footerRef}>
        <Submit type="submit" onClick={signUp} disabled={!formValid}>
          {loading ? <Loading /> : nextText}
        </Submit>
      </Footer>
    </div>
  );
};

const SignUp = props => {
  const [authDetails, setAuthDetails] = useState(null);
  const [user, setUser] = useState(null);

  if (user && authDetails) {
    return <Confirm user={user} authDetails={authDetails} {...props} />;
  }
  return (
    <SignUpForm setUser={setUser} setAuthDetails={setAuthDetails} {...props} />
  );
};

export default SignUp;
