import { atom, selector } from "recoil";
import providerState from "./provider";
import { requestPreviousRequests, requestProviderProfile } from "../httpapi";
import { getIDPCredentials, decryptText } from "../idp";

const user = atom({
  key: "User", // unique ID (with respect to other atoms/selectors)
  default: {
    username: null,
    signedIn: false,
    verified: false,
    name: "",
    phone: "",
    email: "",
    locale: "",
  }, // default value (aka initial value)
});

const getProfiles = async profileUuids => {
  const profileRequests = profileUuids.map(p => requestProviderProfile(p));
  const profiles = await Promise.all(profileRequests);
  return profiles;
};

const decryptMessages = async (messages) => {
  const credentials = await getIDPCredentials();
  const decryptRequests = messages.map(m => {
    if (m && credentials) {
      return Promise.resolve(decryptText(credentials, m));
    }
    return Promise.resolve("");
  });
  const decryptedMessages = await Promise.all(decryptRequests);
  return decryptedMessages;
};

const requestHistory = selector({
  key: "User.RequestHistory",
  get: async ({ get }) => {
    if (!get(user).signedIn || !get(user).verified) {
      return null;
    }
    try {
      const reqRes = await requestPreviousRequests();
      if (!reqRes.success) {
        return null;
      }

      // Get all requests and ensure they are in order of new to old
      const allRequests = reqRes.requests.sort(
        (a, b) => a.createdAt > b.createdAt,
      );

      // Only keep most recent request for each profile
      const requests = allRequests.reduce((accum, curr) => {
        const found = accum.find(r => r.profileUuid === curr.profileUuid);
        if (!found) {
          accum.push(curr);
        }
        return accum;
      }, []);

      // Get profile data and decrypt messages for each request
      const [profiles, messages] = await Promise.all([
        getProfiles(requests.map(r => r.profileUuid)),
        decryptMessages(requests.map(r => r.message)),
      ]);
      requests.forEach((r, i) => {
        const profile = profiles[i].profile;
        r.profilePhotoUrl = profile.photoUrl;
        r.profileName = profile.name;
        r.message = messages[i];
      });
      return requests;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
});

const locale = selector({
  key: "User.Locale",
  get: async ({ get }) => {
    const userData = get(user);
    if (userData.locale) {
      return userData.locale;
    }
    const providerData = get(providerState);
    if (providerData.languageCode && providerData.countryCode) {
      return `${providerData.languageCode}-${providerData.countryCode}`;
    }
    return navigator.language;
  },
});

export default user;
export { requestHistory, locale };
