import { useState } from "react";
import styled from "styled-components";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  InfoBox,
  ErrorBox,
  Passcode,
  TextInput,
  Link,
  PasswordStrength,
} from "@sussex/react-kit/elements";
import { Loading } from "@sussex/react-kit/assets";
import userPool from "../../cognito";
import MemoryStorage from "../../memory-storage";
import { Heading, Container, ErrorText, Submit } from "./Elements";
import useCopy from "../../hooks/useCopy";
import { obfuscateEmail } from "../../utils";

const BoxWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const LinkText = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const ResetPassword = ({ email, onBack }) => {
  const [
    titleText,
    messageText,
    passcodeBodyText,
    passcodeResendText,
    passcodeResendLinkText,
    newPasswordText,
    confirmPasswordText,
    passwordStrengthLengthText,
    passwordStrengthUppercaseText,
    passwordStrengthNumberText,
    submitActionText,
    backActionText,
    confirmText,
    passwordErrorText,
    codeErrorText,
    generalErrorText,
    sendCodeErrorText,
  ] = useCopy([
    "resetPassword.title",
    "resetPassword.message",
    "resetPassword.passcodeBody",
    "resetPassword.passcodeResendText",
    "resetPassword.passcodeResendLink",
    "resetPassword.newPassword",
    "resetPassword.confirmPassword",
    "resetPassword.passwordStrengthLength",
    "resetPassword.passwordStrengthUppercase",
    "resetPassword.passwordStrengthNumber",
    "resetPassword.submitAction",
    "resetPassword.backAction",
    "resetPassword.confirm",
    "resetPassword.passwordError",
    "resetPassword.codeError",
    "resetPassword.generalError",
    "forgotPassword.sendCodeError",
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetComplete, setResetComplete] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const userData = {
    Username: email,
    Pool: userPool,
    Storage: MemoryStorage,
  };

  const cognitoUser = new CognitoUser(userData);

  const handleClick = () => {
    setError(false);

    if (password !== confirmPassword) {
      setError(passwordErrorText);
      return;
    }

    setLoading(true);

    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        setResetComplete(true);
      },
      onFailure: err => {
        setLoading(false);

        if (err) {
          if (err.code === "CodeMismatchException") {
            setError(codeErrorText);
          } else {
            setError(generalErrorText);
          }
        }
      },
    });
  };

  const resendCode = () =>
    new Promise(resolve => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          return resolve();
        },
        onFailure: err => {
          console.error(err);
          setError(sendCodeErrorText);
          return resolve();
        },
      });
    });

  const obfuscatedEmail = obfuscateEmail(email);

  return (
    <Container>
      <Heading>{titleText}</Heading>
      {error && (
        <ErrorBox>
          <ErrorText>{error}</ErrorText>
        </ErrorBox>
      )}
      {resetComplete ? (
        <InfoBox>
          <span>{confirmText}</span>
        </InfoBox>
      ) : (
        <>
          <BoxWrapper>{messageText}</BoxWrapper>
          <Passcode
            obfuscatedEmail={obfuscatedEmail}
            onChange={setCode}
            resend={resendCode}
            loading={loading}
            enterPasscodeBody={passcodeBodyText}
            resendText={passcodeResendText}
            resendLinkText={passcodeResendLinkText}
            unlockIconVisible={false}
          />
          <BoxWrapper>
            <TextInput
              label={newPasswordText}
              value={password}
              type="password"
              onChange={({ target }) => setPassword(target.value)}
              disabled={loading}
            />
          </BoxWrapper>
          <BoxWrapper>
            <TextInput
              label={confirmPasswordText}
              value={confirmPassword}
              type="password"
              onChange={({ target }) => setConfirmPassword(target.value)}
              disabled={loading}
            />
          </BoxWrapper>
          <BoxWrapper>
            <PasswordStrength
              password={password}
              setValid={setValidPassword}
              lengthText={passwordStrengthLengthText}
              uppercaseText={passwordStrengthUppercaseText}
              numberText={passwordStrengthNumberText}
            />
          </BoxWrapper>
          <Submit
            type="submit"
            disabled={code.length < 6 || !validPassword}
            onClick={handleClick}
          >
            {loading ? <Loading /> : submitActionText}
          </Submit>
        </>
      )}
      <LinkText onClick={onBack}>{backActionText}</LinkText>
    </Container>
  );
};

export default ResetPassword;
