import { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import parsePhoneNumber from "libphonenumber-js";
import { Anchor, Button, ErrorBox } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Loading } from "@sussex/react-kit/assets";
import {
  formState,
  stepState,
  summaryState,
  formHasConflicts,
  STEPS,
} from "../state";
import providerState from "../../../state/provider";
import userState, { locale as localeState } from "../../../state/user";
import { getIDPCredentials, encryptText } from "../../../idp";
import { sendRequest } from "../../../httpapi";
import useCopy from "../../../hooks/useCopy";
import Prompt from "../Prompt";
import Footer from "../Footer";
import ContactInfoIcon from "../../../assets/ContactInfoIcon";
import CommentsIcon from "../../../assets/CommentsIcon";
import PaymentIcon from "../../../assets/PaymentIcon";
import TherapyNeedsIcon from "../../../assets/TherapyNeedsIcon";
import TimeIcon from "../../../assets/TimeIcon";
import Checkmark from "../../../assets/Checkmark";
import Error from "../../../assets/Error";
import { formatTimeRange } from "../../../utils";

const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  overflow: hidden;
`;

const StepIconWrapper = styled.div`
  width: 24px;
`;

const StepBody = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-family: ${({ theme }) => theme.fonts.primary};
  width: 100%;
  margin: 0 20px;
  h3 {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    line-height: 1;
    margin: 0 0 10px;
  }
  & > * {
    margin: 0 0 6px;
    line-height: 1.5;
  }
  & > *:last-child {
    margin-bottom: -4px;
  }
`;

const StepItem = styled.div`
  color: ${({ theme, conflict }) =>
    conflict ? theme.colors.darkRed : theme.colors.default};
`;

const ConflictWrapper = styled.span`
  margin-left: 5px;
`;

const StepEditWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StepEditButton = styled(Button)`
  min-width: 57px;
  padding: 8px 12px;
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  p {
    margin: 0 10px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const SubmitButton = styled(Button)`
  height: 56px;
  width: 100%;
`;

const TOS = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.33;
  margin: 10px auto 0;
  a {
    font-family: ${({ theme }) => theme.fonts.primary};
  }
`;

const ErrorWrapper = styled.div`
  padding-bottom: 24px;
  a {
    color: inherit;
  }
`;

const formatTime = (startTimestamp, timezone, locale) => {
  const startTime = new Date(startTimestamp);
  const stopTime = new Date(startTime);
  stopTime.setHours(startTime.getHours() + 1);

  return (
    startTime.toLocaleDateString(locale, {
      day: "numeric",
      month: "long",
      year: "numeric",
    }) +
    " - " +
    formatTimeRange(startTime, stopTime, timezone, locale)
  );
};

const Step = ({ icon, header, items, onEdit }) => {
  const [editText] = useCopy(["steps.summary.editButtonText"]);
  return (
    <StepWrapper>
      <StepIconWrapper>{icon()}</StepIconWrapper>
      <StepBody>
        <h3>{header}</h3>
        {items.map((val, i) => {
          if (!val) {
            return null;
          }
          return (
            <StepItem key={i} conflict={!!val.conflict}>
              {val.title || val}
              {val.conflict && (
                <ConflictWrapper>
                  <Error width={12} height={10.8} />
                </ConflictWrapper>
              )}
            </StepItem>
          );
        })}
      </StepBody>
      {onEdit && (
        <StepEditWrapper>
          <StepEditButton onClick={onEdit}>{editText}</StepEditButton>
        </StepEditWrapper>
      )}
    </StepWrapper>
  );
};

const Summary = ({ next, footerRef }) => {
  const [loading, setLoading] = useState(false);
  const formData = useRecoilValue(formState);
  const formDataHasConflitcs = useRecoilValue(formHasConflicts);
  const { profileUuid, timezone } = useRecoilValue(providerState);
  const { name, email, phone } = useRecoilValue(userState);
  const locale = useRecoilValue(localeState);
  const setSummary = useSetRecoilState(summaryState);
  const setCurrentStep = useSetRecoilState(stepState);
  const [pendingRequestErrorId, setPendingRequestErrorId] = useState(null);
  const [otherRequestError, setOtherRequestError] = useState(false);
  const [
    prompt,
    contactInfoHeader,
    timeHeader,
    therapyNeedsHeader,
    paymentHeader,
    commentsHeader,
    sendButtonText,
    tosText,
    tosLinkText,
    privacyPolicyLinkText,
    paymentMethodCashText,
    paymentMethodInsuranceText,
    conflictError,
    pendingRequestErrorText,
    pendingRequestErrorLinkText,
    otherRequestErrorText,
    outOfNetworkText,
  ] = useCopy([
    "steps.summary.prompt",
    "steps.summary.contactInfoHeader",
    "steps.summary.timeHeader",
    "steps.summary.therapyNeedsHeader",
    "steps.summary.paymentHeader",
    "steps.summary.commentsHeader",
    "steps.summary.sendButtonText",
    "steps.summary.tos",
    "steps.summary.tosLink",
    "steps.summary.privacyPolicyLink",
    "steps.payment.cash",
    "steps.payment.insurance",
    "steps.summary.conflictError",
    "steps.summary.pendingRequestError",
    "steps.summary.pendingRequestErrorLink",
    "steps.summary.otherRequestError",
    "steps.payment.outOfNetwork",
  ]);

  const handleClick = async () => {
    setLoading(true);
    let encrypted = "";
    if (formData.message) {
      const credentials = await getIDPCredentials();
      if (credentials) {
        encrypted = await encryptText(credentials, formData.message);
      }
      if (!encrypted) {
        setOtherRequestError(true);
        setLoading(false);
        return;
      }
    }
    const date = new Date(formData.time).toISOString().substr(0, 10);
    const time = new Date(formData.time).toTimeString().substr(0, 5);
    const request = {
      profileUuid,
      date,
      time,
      timezone,
      otherIssue: formData.otherIssue,
      issues: formData.issues,
      paymentMethod: formData.paymentMethod,
      insurances: formData.insurances,
      otherInsurance: formData.otherInsurance,
      outOfNetwork: formData.outOfNetwork,
      message: encrypted,
    };
    const res = await sendRequest(request);
    setLoading(false);
    if (res.success) {
      next();
      return;
    }
    if (res.status === 409) {
      setPendingRequestErrorId(res.conflictId);
      return;
    }
    setOtherRequestError(true);
  };

  const getIssues = () => {
    const issues = [...formData.issues];
    if (formData.otherIssue) {
      issues.push({
        title: formData.otherIssue,
      });
    }
    return issues;
  };

  const getPaymentMethod = () => {
    if (formData.paymentMethod === "cash") {
      return [paymentMethodCashText];
    }
    const insurances = [...formData.insurances];
    insurances.forEach((ins, i) => {
      insurances[i] = {
        ...ins,
        title: `${paymentMethodInsuranceText} / ${ins.title}`,
      };
    });
    if (formData.otherInsurance) {
      insurances.push({
        title: `${paymentMethodInsuranceText} / ${formData.otherInsurance}`,
        conflict: formData.otherInsuranceConflict,
      });
    }
    if (formData.outOfNetwork) {
      insurances.push({
        title: `${outOfNetworkText} / ${formData.outOfNetwork}`,
        conflict: formData.outOfNetworkConflict,
      });
    }
    return insurances;
  };

  useEffect(() => {
    setSummary(true);
  }, [setSummary]);

  return (
    <>
      {pendingRequestErrorId && (
        <ErrorWrapper>
          <ErrorBox>
            {swapCopyVariables(pendingRequestErrorText, {
              EMAIL: email,
              LINK: (
                <a
                  href={`${process.env.REACT_APP_CLIENT_URL}/requests/${pendingRequestErrorId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {pendingRequestErrorLinkText}
                </a>
              ),
            })}
          </ErrorBox>
        </ErrorWrapper>
      )}
      {otherRequestError && (
        <ErrorWrapper>
          <ErrorBox>{otherRequestErrorText}</ErrorBox>
        </ErrorWrapper>
      )}
      {formDataHasConflitcs && (
        <ErrorWrapper>
          <ErrorBox>{conflictError}</ErrorBox>
        </ErrorWrapper>
      )}
      <Prompt>{prompt}</Prompt>
      <Step
        icon={() => <ContactInfoIcon />}
        header={contactInfoHeader}
        items={[
          name,
          <EmailWrapper>
            <p>{email}</p>
            <Checkmark />
          </EmailWrapper>,
          parsePhoneNumber(phone)?.formatNational(),
        ]}
      />
      <Step
        icon={() => <TimeIcon />}
        header={timeHeader}
        items={[formatTime(formData.time, timezone, locale)]}
        onEdit={() => setCurrentStep(STEPS.TIME)}
      />
      <Step
        icon={() => <TherapyNeedsIcon />}
        header={therapyNeedsHeader}
        items={getIssues()}
        onEdit={() => setCurrentStep(STEPS.THERAPY_NEEDS)}
      />
      <Step
        icon={() => <PaymentIcon />}
        header={paymentHeader}
        items={getPaymentMethod()}
        onEdit={() => setCurrentStep(STEPS.PAYMENT_METHOD)}
      />
      <Step
        icon={() => <CommentsIcon />}
        header={commentsHeader}
        items={[formData.message]}
        onEdit={() => setCurrentStep(STEPS.COMMENTS)}
      />

      <Footer footerRef={footerRef}>
        <SubmitButton
          onClick={handleClick}
          type="submit"
          disabled={pendingRequestErrorId}
        >
          {loading ? <Loading /> : sendButtonText}
        </SubmitButton>
        <TOS>
          {swapCopyVariables(tosText, {
            TOS_LINK: <Anchor href="">{tosLinkText}</Anchor>,
            PRIVACY_POLICY_LINK: (
              <Anchor href="">{privacyPolicyLinkText}</Anchor>
            ),
          })}
        </TOS>
      </Footer>
    </>
  );
};

export default Summary;
