import { rawTimeZones } from "@vvo/tzdb";

const isIframe = window.self !== window.top;
const isPopup = !!window.opener;

export const postMessageToParent = msg => {
  let opener = null;
  if (isIframe) {
    opener = window.parent;
  }
  if (isPopup) {
    opener = window.opener;
  }
  if (!opener) {
    return;
  }
  opener.postMessage(msg, "*");
};

export const close = () => {
  console.log("TODO: close");
  window.location.reload();
};

export const getTimezoneAbbreviation = tz => {
  const value = rawTimeZones.find(timeZone => {
    return timeZone.name === tz || timeZone.group.includes(tz);
  });

  return value ? value.abbreviation : "";
};

export const formatTimeRange = (start, stop, timezone, locale) => {
  const from = start.toLocaleTimeString(locale, { timeStyle: "short" });
  const to = stop.toLocaleTimeString(locale, { timeStyle: "short" });
  const tz = getTimezoneAbbreviation(timezone);

  return `${from} - ${to} ${tz}`;
};

export const obfuscateEmail = email => {
  if (!email) {
    return;
  }
  const parts = email.split("@");
  const name = parts[0];
  return (
    name[0] +
    "*".repeat(name.length - 2) +
    name[name.length - 1] +
    "@" +
    parts[1]
  );
};
