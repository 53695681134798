const baseURL = `${process.env.REACT_APP_API_URL}`;
const baseAuthURL = `${process.env.REACT_APP_AUTH_API_URL}`;

const getCurrentUser = async () => {
  let user = null;
  try {
    const response = await fetch(`${baseAuthURL}/clients/get-user`, {
      method: "GET",
      credentials: "include",
    });
    if (response.ok) {
      const json = await response.json();
      if (json.uuid) {
        user = {
          uuid: json.uuid,
          name: json.name,
          email: json.email,
          emailVerified: json.emailVerified,
          phoneNumber: json.phoneNumber,
          phoneNumberVerified: json.phoneNumberVerified,
          locale: json.locale,
          idToken: json.idToken,
        };
      }
    }
  } catch (e) {
    console.log(e);
  }
  return user;
};

const getUserStatus = async username => {
  let status = "";
  try {
    const response = await fetch(`${baseAuthURL}/clients/get-status?username=${encodeURIComponent(username)}`, {
      method: "GET",
    });
    if (response.ok) {
      const json = await response.json();
      status = json.status;
    }
  } catch (e) {
    console.log(e);
  }
  return status;
};

const requestPreviousRequests = async () => {
  const res = {
    success: true,
    requests: [],
  };
  try {
    const response = await fetch(`${baseURL}/requests`, {
      method: "GET",
      credentials: "include",
    });
    const json = await response.json();
    if (!response.ok) {
      res.status = response.status;
      throw new Error(json.errorMessage);
    }
    res.requests = json.requests;
  } catch (e) {
    res.success = false;
  }
  return res;
};

const requestProviderProfile = async profileUUID => {
  let res = {
    success: true,
    profile: null,
  };
  try {
    const response = await fetch(`${baseURL}/profiles/${profileUUID}`, {
      method: "GET",
    });
    const json = await response.json();
    if (!response.ok) {
      res.status = response.status;
      throw new Error(json.errorMessage);
    }
    res.profile = json.profile;
  } catch (e) {
    res.success = false;
  }
  return res;
};

const sendRequest = async requestPayload => {
  let res = {
    success: true,
    id: "",
  };
  try {
    const response = await fetch(`${baseURL}/requests`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestPayload),
    });
    const json = await response.json();
    if (!response.ok) {
      res.status = response.status;
      if (response.status === 409) {
        res.conflictId = json.errorMessage.replace("ConflictError: ", "");
      }
      throw new Error(json.errorMessage);
    }
    res.id = json.id;
  } catch (e) {
    res.success = false;
  }
  return res;
};

const logoutUser = async () => {
  let res = {
    success: true,
  };
  try {
    const response = await fetch(`${baseAuthURL}/clients/logout`, {
      method: "POST",
      credentials: "include",
    });
    const json = await response.json();
    if (!response.ok) {
      res.status = response.status;
      throw new Error(json.errorMessage);
    }
  } catch (e) {
    res.success = false;
  }
  return res;
};

export {
  getCurrentUser,
  getUserStatus,
  requestPreviousRequests,
  requestProviderProfile,
  sendRequest,
  logoutUser,
};
