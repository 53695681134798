import styled from "styled-components";
import { useState } from "react";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { InfoBox, ErrorBox, Passcode } from "@sussex/react-kit/elements";
import { useSetRecoilState } from "recoil";
import { AuthenticationDetails } from "amazon-cognito-identity-js";
import useCopy from "../../hooks/useCopy";
import userState from "../../state/user";
import { obfuscateEmail } from "../../utils";

const Wrapper = styled.div`
  flex-direction: column;
`;

const BoxWrapper = styled.div`
  margin-bottom: 20px;
`;

const Confirm = ({ next, authDetails, user, skipLogin }) => {
  const [
    errorSendingCodeText,
    codeResentText,
    enterPasscodeTitle,
    enterPasscodeBody,
    resendText,
    resendLinkText,
    errorInvalidCodeText,
  ] = useCopy([
    "confirmAccount.errorResending",
    "confirmAccount.passcodeResent",
    "confirmAccount.enterPasscodeTitle",
    "confirmAccount.enterPasscodeBody",
    "confirmAccount.resend",
    "confirmAccount.resendLink",
    "confirmAccount.errorInvalidCode",
  ]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [codeResent, setCodeResent] = useState(null);
  const setUserData = useSetRecoilState(userState);

  const resend = () =>
    new Promise(resolve => {
      setCodeResent(false);
      user.resendConfirmationCode((err, result) => {
        if (err) {
          setError(errorSendingCodeText);
          console.error(err);
          return;
        }
        setCodeResent(true);
        return resolve(result);
      });
    });

  const confirm = code => {
    setLoading(true);
    setCodeResent(false);
    setError(null);
    user.confirmRegistration(code, true, (err, result) => {
      if (err) {
        setLoading(false);
        if (err.code === "CodeMismatchException") {
          setError(errorInvalidCodeText);
          return;
        }
        console.error(err.message || JSON.stringify(err));
        return;
      }

      if (skipLogin) {
        next();
        return;
      }

      // Login User
      const authenticationDetails = new AuthenticationDetails({
        Username: authDetails.email,
        Password: authDetails.password,
      });
      user.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          user.getUserAttributes((err, attrResult) => {
            setLoading(false);
  
            if (err) {
              console.error("Error getting user attributes", err);
              return;
            }
  
            let name = "", phone = "", locale = "";
  
            attrResult.forEach(a => {
              if (a.Name === "name") {
                name = a.Value;
              }
              if (a.Name === "phone_number") {
                phone = a.Value;
              }
              if (a.Name === "locale") {
                locale = a.Value;
              }
            });
  
            setUserData({
              signedIn: true,
              verified: true,
              username: authDetails.email,
              name: name,
              email: authDetails.email,
              phone: phone,
              locale: locale,
            });
  
            next();
          });
        },
        onFailure: err => {
          setLoading(false);
          console.error(err.message || JSON.stringify(err));
        },
      });
    });
  };

  const obfuscatedEmail = obfuscateEmail(authDetails.email);

  return (
    <Wrapper>
      {codeResent && (
        <BoxWrapper>
          <InfoBox>
            {swapCopyVariables(codeResentText, {
              EMAIL: obfuscatedEmail,
            })}
          </InfoBox>
        </BoxWrapper>
      )}
      {error && (
        <BoxWrapper>
          <ErrorBox>{error}</ErrorBox>
        </BoxWrapper>
      )}
      <Passcode
        obfuscatedEmail={obfuscatedEmail}
        onSubmit={confirm}
        resend={resend}
        loading={loading}
        enterPasscodeTitle={enterPasscodeTitle}
        enterPasscodeBody={enterPasscodeBody}
        resendText={resendText}
        resendLinkText={resendLinkText}
      />
    </Wrapper>
  );
};

export default Confirm;
